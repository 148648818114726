export const siteIndications = {
  hcp: 'hcp',
  patient: 'patient',
  nutritionPatient: 'nutritionPatient',
  nutritionHcp: 'nutritionHcp',
  nutritionVideo: 'nutritionVideo',
  landingPage: 'landingPage',
  ndSignup: 'ndSignup',
};

export const siteBrandNames = {
  nascobal: 'NASCOBAL<sup class="brand-reg-mark">&reg;</sup>',
  nutrition:
    '<nobr>Nutrition Direct<span class="brand-trade-mark">&trade;</span></nobr>',
  bariactiv: 'BariActiv<sup class="brand-reg-mark">&reg;</sup>',
};

export const siteJobCodes = {
  NASCOBAL_PAT: 'NS-05583; NS-05763; NS-05821/November 2023',
  NASCOBAL_HCP: 'NS-05584; NS-05764; NS-05822/November 2023',
  NUTRI_DIRECT_PAT: 'NS-05586; NS-05772; NS-05832/November 2023',
  NUTRI_DIRECT_HCP: 'NS-05585; NS-05773; NS-05827/November 2023',
  TODAYS_DIETITIAN_LANDING_PAGE: 'NS-05591/May 2021',
  NUTRITION_DIRECT_VIDEOS_PAGE: 'NS-05625/May 2021',
  NUTRITION_DIRECT_SIGNUP_PAGE: 'NS-05794/August 2023',
};

export const patientData = {
  pageTitle: `NASCOBAL® | For Patients`,
  pageDescription: `Find complete information about NASCOBAL® Nasal Sprayy`,
  pageKeywords: ['Nascobal, Vitamin B12, Nasal Spray'],
  jobCode: `NS-05583; NS-05763; NS-05821/November 2023`,
};

export const hcpData = {
  pageTitle: `NASCOBAL® | For Healthcare Professionals`,
  pageDescription: `Find complete information about NASCOBAL® Nasal Spray`,
  pageKeywords: ['Nascobal, Vitamin B12, Nasal Spray'],
  jobCode: `NS-05583; NS-05763; NS-05822/November 2023`,
};

export const nutritionPatient = {
  pageTitle: `NUTRITION DIRECT™ | Patient Home`,
  pageDescription: `Vitamin B12 deficiency after bariatric surgery`,
  pageKeywords: ['Nascobal, Vitamin B12, Nasal Spray'],
  jobCode: `NS-05586; NS-05772/May 2023`,
};
export const nutritionHcp = {
  pageTitle: `NUTRITION DIRECT™ | For Healthcare Professionals`,
  pageDescription: `Give your patients nutritional support`,
  pageKeywords: ['Nascobal, Vitamin B12, Nasal Spray'],
  jobCode: `NS-05585; NS-05773/May 2023`,
};

export const siteMetaData = {
  SITE_NAME: `NASCOBAL®`,
  SITE_DEFAULT_DESCRIPTION: `Test product`,
  SITE_DEFAULT_KEYWORDS: ['brandx', 'brand', 'dfa', 'deerfield'],
  PATIENT_JOB_CODE: `XX-XXXX/March 2020`,
  HCP_JOB_CODE: `XX-XXXX/March 2020`,
  CURRENT_YEAR: new Date().getFullYear(),
};

const metaData = {
  patient: {
    ...patientData,
  },
  hcp: {
    ...hcpData,
  },
  nutritionPatient: {
    ...nutritionPatient,
  },
  nutritionHcp: {
    ...nutritionHcp,
  },
  ...siteMetaData,
  title: 'NASCOBAL®',
  brandNames: {
    ...siteBrandNames,
  },
  jobCodes: {
    ...siteJobCodes,
  },
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */

/**
 * const ISI_HEIGHT = 185;
 * const ISI_COLLAPSED_HEIGHT = 115;
 */

const ISI_HEIGHT = 390;
const ISI_HEIGHT_DESKTOP = 185;

const ISI_COLLAPSED_HEIGHT = 115;
const siteOptions = {
  INDICATIONS: {
    ...siteIndications,
  },
  HEADER_TYPE: 'fixed',
  STICKY_HEIGHT_DESKTOP: ISI_HEIGHT_DESKTOP,
  STICKY_HEIGHT: ISI_HEIGHT,
  STICKY_COLLAPSED_HEIGHT: ISI_COLLAPSED_HEIGHT,
  ISI_OPTIONS: {
    TYPE: 'sticky',
    STICKY_STYLES: {
      backgroundColor: '#ffffff',
      height: ISI_HEIGHT,
      boxShadow: '-1px -5px 15px 0px rgba(0,0,0,0.10)',
      paddingTop: 5,
    },
  },
};
const hcpModalInfo = {
  TITLE: `HEALTHCARE PROFESSIONALS`,
  BODY: `<p>The information contained in this section of the site is intended for U.S. Healthcare Professionals only. Click continue if you are a healthcare professional.</p>`,
  BUTTON_ONE_CAPTION: `Cancel`,
  BUTTON_TWO_CAPTION: `Continue`,
  CLASS_NAME: `brandx-modal brandx-hcp-modal`,
};
const externalLinkInfo = {
  TITLE: `BRANDX Website Message`,
  BODY: `You are going away from BRANDX website. Do you want to continue?`,
  LINK_CLASS_NAME: `brandx-external-link`,
  OVERLAY_CLASS_NAME: `brandx-modal brandx-external-modal`,
};

const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
  hcpModalInfo: hcpModalInfo,
  externalLinkInfo: externalLinkInfo,
};

export default appConfigs;

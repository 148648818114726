import React, { useState, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';

import appConfigs from '../../../app.settings';
import { getWidth } from 'libs/utilityHelper';

export const appContext = React.createContext();

const AppProvider = props => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [currentIndication, setCurrentIndication] = useState('');
  const [magicshot, setMagicshot] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [windowWidth, setWindowWidth] = useState();

  const MOBILE_VIEW = 1023;
  const isSmallerDevice = windowWidth < MOBILE_VIEW;

  const updateWindowWidth = _width => {
    setWindowWidth(_width);
  };

  useEffect(() => {
    updateWindowWidth(getWidth());
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      updateWindowWidth(getWidth());
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [windowWidth]);

  const updateHeaderHeight = _height => {
    setHeaderHeight(_height);
  };

  const updateCurrentIndication = _indication => {
    setCurrentIndication(_indication);
  };

  const updateMagicshot = _flag => {
    setMagicshot(_flag);
  };

  useEffect(() => {
    appConfigs.siteOptions.HEADER_TYPE === 'fixed'
      ? setTopOffset(headerHeight)
      : setTopOffset(0);
  }, [headerHeight]);
  return (
    <appContext.Provider
      value={{
        appConfigs,
        currentIndication,
        headerHeight,
        magicshot,
        topOffset,
        updateHeaderHeight,
        updateCurrentIndication,
        updateMagicshot,
        windowWidth,
        isSmallerDevice,
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};

export default ({ element }) => (
  <AppProvider>
    <CookiesProvider>{element}</CookiesProvider>
  </AppProvider>
);

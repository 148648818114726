/**
 *
 * @param {*} gaObject
 *
 * Example object
 * {
 *  category: "Main Nav",
 *  action: "Click",
 *  label: "About Page"
 * }
 */

const pushEventGA = gaObject => {
  const isGA = typeof window.ga !== 'undefined';
  if (isGA) {
    window.ga(
      'send',
      'event',
      gaObject.category,
      gaObject.action,
      gaObject.label,
    );
  } else {
    console.log('GoogleAnalytics is not live yet!');
    console.log(gaObject);
  }
};

const scrollToElement = (_elementID, _offsetTop) => {
  const el = document.getElementById(_elementID);
  // CanIuse scrollTo support IE 11, Chrome, Safari
  if (el) {
    const topOffset = _offsetTop || 0;
    window.scrollTo(0, el.offsetTop - topOffset);
  }
};

const checkUrlParamFor = _param => {
  const queryStrings =
    typeof window !== 'undefined'
      ? window.location.search.replace('?', '')
      : '';
  const param = _param.toLowerCase().trim();
  return param ? queryStrings.search(param) >= 0 : false;
};

const copyrightYear = () => new Date().getFullYear();

const siteIndications = {
  hcp: 'hcp',
  patient: 'patient',
  nutritionPatient: 'nutritionPatient',
  nutritionHcp: 'nutritionHcp',
  nutritionVideo: 'nutritionVideo',
  landingPage: 'landingPage',
};

const getIndication = () => {
  const currentPathname =
    typeof window !== 'undefined' && window?.location?.pathname.toLowerCase();

  if (currentPathname && currentPathname?.indexOf('/vitaminb12/hcp') !== -1) {
    return siteIndications.hcp;
  } else if (
    currentPathname &&
    currentPathname?.indexOf('/nutritiondirect') !== -1
  ) {
    return siteIndications.nutritionPatient;
  } else if (
    currentPathname &&
    currentPathname?.indexOf('/nutritiondirect/hcp') !== -1
  ) {
    return siteIndications.nutritionHcp;
  } else if (
    currentPathname &&
    currentPathname?.indexOf('/todaysdietitian') !== -1
  ) {
    return siteIndications.landingPage;
  } else if (
    currentPathname &&
    currentPathname?.indexOf('/nutritiondirect/videos') !== -1
  ) {
    return siteIndications.nutritionVideo;
  } else {
    return siteIndications.patient;
  }
};

export const envName = {
  nonProd: 'nonProd',
  prod: 'prod',
};

export const getEnv = () => {
  const currentUrl = typeof window !== 'undefined' && window?.location.href;
  if (currentUrl) {
    // const local = ['localhost', 'develop--'];
    // const local = ['develop--'];
    const local = [];
    const isLocal = local.some(env => currentUrl.indexOf(env) !== -1);

    return isLocal ? envName.nonProd : envName.prod;
  }
};

const getWidth = () => {
  if (typeof window !== 'undefined') {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
};

export {
  pushEventGA,
  scrollToElement,
  checkUrlParamFor,
  copyrightYear,
  getIndication,
  siteIndications,
  getWidth,
};
